import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Rest Day.`}</p>
    <h4><strong parentName="h4"><em parentName="strong">{`*`}{`The CrossFit Open starts February 21st! To prepare we’ll be throwing in some past Open WOD’s in our class programming. Register for the Open at: Games.CrossFit.com.`}</em></strong></h4>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      